import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import WebriqForm from '../components/Form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './agency.css'

class AgencyPage extends React.Component {
  render() {
    const siteTitle = 'JAMstack for Web and Digital Agencies'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="webriq-agency pb-5">
          <section id="offered" className="pb-5 mb-5 pt-5 mt-sm-5">
            <Container>
              <Col md={11} className="mx-auto">
                <Row className="justify-content-center">
                  <Col xs="6" lg={3} className="text-center mb-4 mb-lg-0">
                    <LazyLoadImage
                      effect="blur"
                      className="img-fluid px-4 px-sm-5"
                      src="/img/icon-client.png"
                      alt="client"
                    />
                    <h5 className="font-weight-normal mt-2">
                      Your / Our Clients
                    </h5>
                  </Col>
                  <Col xs="6" lg={3} className="text-center mb-4 mb-lg-0">
                    <LazyLoadImage
                      effect="blur"
                      className="img-fluid px-4 px-sm-5"
                      src="/img/icon-design.png"
                      alt="design"
                    />
                    <h5 className="font-weight-normal mt-2">
                      Your / Our Design
                    </h5>
                  </Col>
                  <Col xs="6" lg={3} className="text-center mb-4 mb-lg-0">
                    <LazyLoadImage
                      effect="blur"
                      className="img-fluid px-4 px-sm-5"
                      src="/img/icon-code.png"
                      alt="code"
                    />
                    <h5 className="font-weight-normal mt-2">Our Code</h5>
                  </Col>
                  <Col xs="6" lg={3} className="text-center mb-4 mb-lg-0">
                    <LazyLoadImage
                      effect="blur"
                      className="img-fluid px-4 px-sm-5"
                      src="/img/icon-label.png"
                      alt="label"
                    />
                    <h5 className="font-weight-normal mt-2">
                      Your / Co-branded / Our Label
                    </h5>
                  </Col>
                </Row>
              </Col>
            </Container>
          </section>
          <section id="developers">
            <div className="intro text-center">
              <h3 className="font-weight-normal text-blue">What we offer</h3>
            </div>
            <div id="developer-content">
              <Container>
                <Row className="align-items-center row justify-content-center">
                  <Col md={5} className="ml-lg-auto">
                    <div className="content">
                      <Row className="align-items-center justify-content-start">
                        <Col xs="auto">
                          <LazyLoadImage
                            className="img-fluid"
                            effect="blur"
                            src="/img/offer-1.png"
                            alt="3"
                          />
                        </Col>
                        <Col xs={`${8} pl-0 pl-sm-3`} sm={7}>
                          <h5 className="font-weight-normal">
                            A pool of developers specialized in modern web
                            technologies such as React, GraphQL and Serverless
                            functions
                          </h5>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="text-right">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/developers.png"
                        alt="developers"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section id="web-portal" className="mt-5 pt-5">
            <Container>
              <Row>
                <Col lg={5} className="text-center mb-5 mb-lg-0">
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/dedicated-projects.png"
                    alt="web portal project"
                  />
                </Col>
                <Col lg={7}>
                  <div className="content">
                    <Row className="align-items-center justify-content-start">
                      <Col xs="auto">
                        <LazyLoadImage
                          className="img-fluid"
                          effect="blur"
                          src="/img/offer-2.png"
                          alt="2"
                        />
                      </Col>
                      <Col xs={`${8} pl-0 pl-sm-3`} sm={6}>
                        <h5 className="font-weight-normal">
                          A dedicated Web Portal for all your projects
                        </h5>
                      </Col>
                    </Row>
                    <ul className="list-unstyled d-flex flex-wrap list-offer pl-sm-5 ml-sm-5 mt-3 justify-content-between">
                      <li>
                        <div className="checked-icon">
                          <LazyLoadImage
                            effect="blur"
                            src="/img/checked-blue.png"
                            alt="checked"
                            width={35}
                          />
                        </div>
                        <span className="pl-3">
                          Unlimited Number of Projects
                        </span>
                      </li>
                      <li>
                        <div className="checked-icon">
                          <LazyLoadImage
                            effect="blur"
                            src="/img/checked-blue.png"
                            alt="checked"
                            width={35}
                          />
                        </div>
                        <span className="pl-3">
                          GIT based and Headless CMS systems
                        </span>
                      </li>
                      <li>
                        <div className="checked-icon">
                          <LazyLoadImage
                            effect="blur"
                            src="/img/checked-blue.png"
                            alt="checked"
                            width={35}
                          />
                        </div>
                        <span className="pl-3">
                          Unlimited WebriQ Forms and WebriQ Payment Forms
                        </span>
                      </li>
                      <li>
                        <div className="checked-icon">
                          <LazyLoadImage
                            effect="blur"
                            src="/img/checked-blue.png"
                            alt="checked"
                            width={35}
                          />
                        </div>
                        <span className="pl-3">
                          Google Analytics integrated
                        </span>
                      </li>
                      <li>
                        <div className="checked-icon">
                          <LazyLoadImage
                            effect="blur"
                            src="/img/checked-blue.png"
                            alt="checked"
                            width={35}
                          />
                        </div>
                        <span className="pl-3">
                          WebriQ Analytics integrated
                        </span>
                      </li>
                      <li>
                        <div className="checked-icon">
                          <LazyLoadImage
                            effect="blur"
                            src="/img/checked-blue.png"
                            alt="checked"
                            width={35}
                          />
                        </div>
                        <span className="pl-3">SSL certificate integrated</span>
                      </li>
                      <li>
                        <div className="checked-icon">
                          <LazyLoadImage
                            effect="blur"
                            src="/img/checked-blue.png"
                            alt="checked"
                            width={35}
                          />
                        </div>
                        <span className="pl-3">
                          Hosting of all your projects on a dedicated CDN
                        </span>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section id="helpdesk">
            <Container>
              <Row className="align-items-center justify-content-center">
                <Col md={5} className="ml-lg-auto">
                  <div className="content">
                    <Row className="align-items-center justify-content-start">
                      <Col xs="auto">
                        <LazyLoadImage
                          className="img-fluid"
                          effect="blur"
                          src="/img/offer-3.png"
                          alt="3"
                        />
                      </Col>
                      <Col xs={`${8} pl-0 pl-sm-3`} sm={7} lg={8}>
                        <h5 className="font-weight-normal">
                          Helpdesk support <br /> email, Chat and speak to
                          humans through Slack
                        </h5>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="text-center">
                    <LazyLoadImage
                      className="img-fluid"
                      effect="blur"
                      src="/img/helpdesk.png"
                      alt="helpdesk person"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section id="building-blocks" className="my-md-5 py-5">
            <Container>
              <Row>
                <Col xs={12} className="text-center">
                  <div className="building-blocks_header mb-5 mx-auto">
                    <h3>Our Building Blocks</h3>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={3}>
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/blocks-mongodb.png"
                      className="img-fluid"
                    />
                    <p>A comprehensive Database (MongoDB) Service</p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/blocks-nodejs.png"
                      className="img-fluid"
                    />
                    <p>
                      A Javascript (Node.js) API Content Management Framework
                    </p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/blocks-gatsby.png"
                      className="img-fluid"
                    />
                    <p>A State of the Art Static Site Generator</p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/blocks-netlify.png"
                      className="img-fluid"
                    />
                    <p>An ultra-redundant global CDN</p>
                  </div>
                </Col>
                <div className="col-6 col-sm-3">
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/blocks-git.png"
                      className="img-fluid"
                    />
                    <p>An open-source GIT based CMS System</p>
                  </div>
                </div>
                <Col md={3}>
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/blocks-webriq.png"
                      className="img-fluid"
                    />
                    <p>Manage Web forms and Payment forms serverless</p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/blocks-bigcommerce.png"
                      className="img-fluid"
                    />
                    <p>build enterprise-grade ecommerce websites</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section id="partnership" className="component pb-5">
            <Container>
              <hr className="border-blue mb-5" />
              <Col md={10} className="mx-auto">
                <div className="intro text-center mb-5">
                  <h3 className="font-weight-normal text-blue">
                    Partnership Request
                  </h3>
                </div>
                <WebriqForm
                  id="request-form"
                  className="style1"
                  webriqform="true"
                  name="Partner Request Form"
                  data-form-id="5d9c4a98f4ade322f456095b"
                  data-thankyou-url="/thank-you-agency"
                >
                  <Row>
                    <Col md={6}>
                      <div className="form-group">
                        <input
                          name="Company Name"
                          type="text"
                          className="form-control"
                          id="InputName"
                          required
                        />
                        <label htmlFor="InputName">Company Name*</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group">
                        <input
                          name="Location"
                          type="text"
                          className="form-control"
                          id="InputLocation"
                          required
                        />
                        <label htmlFor="InputLocation">Location*</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group">
                        <input
                          name="Business Email Address"
                          type="email"
                          className="form-control"
                          id="InputEmail"
                          required
                        />
                        <label htmlFor="InputEmail">
                          Business Email Address*
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group">
                        <input
                          name="Telephone number"
                          type="text"
                          className="form-control"
                          id="InputTelephone"
                          required
                        />
                        <label htmlFor="InputTelephone">
                          Telephone Number*
                        </label>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="form-group">
                        <textarea
                          name="Why are you interested in our services"
                          className="form-control"
                          id="InputMessage"
                          rows="2"
                        />
                        <label htmlFor="InputMessage">
                          Why are you interested in our services
                        </label>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <span className="font-italic text-muted small">
                        {' '}
                        * required field
                      </span>
                    </Col>
                    <Col xs={12}>
                      <div className="form-group mt-4">
                        <div className="webriq-recaptcha" />
                      </div>
                    </Col>
                  </Row>
                  <button
                    name="submit"
                    type="submit"
                    className="btn btn-primary hvr-shadow"
                  >
                    Submit
                  </button>
                </WebriqForm>
              </Col>
            </Container>
          </section>
        </div>
      </Layout>
    )
  }
}

export default AgencyPage

export const AgencyPageQuery = graphql`
  query AgencyPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
